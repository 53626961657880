import * as React from 'react';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { IconDownload, IconPreview, IconDownloadZip } from '../../Atoms/Icons/Icons';

import './DocumentView.scss';
import { trackDocumentEvent, trackPublicationsDocument, trackDocumentEvent2 } from '../../Utils/Tracking';

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

const trackEvent = (name, currentPage, title, button_type) => {

  console.log(currentPage)
  switch (currentPage) {
    case "page_gouvernance":
      // event,page_name,page_type,origin_page,button_type,pdf_name
      trackDocumentEvent2('clic_pdf_telecharge', 'la_gouvernance', 'groupe', 'accueil', button_type, name)
      break;
    case "principaux_enjeux":
      trackDocumentEvent('clic_pdf_telecharge', 'nos_principaux_enjeux', 'engagements', 'publications_rse', 'accueil', button_type, name)
      break;
    case "page_finance":
      trackDocumentEvent('clic_pdf_telecharge', 'informations_financieres', 'finance', 'dividende', 'accueil', button_type, name)
      break;
    case "information_reglementees":
      trackDocumentEvent('clic_pdf_telecharge', 'information_reglementees', 'finance', title, 'accueil', button_type, name)
      break;
    case "page_media":
      trackPublicationsDocument('clic_pdf_telecharge', 'media', 'accueil', true, 'publications', name)
      break;
  }



}


const getSingleDocument = ({
  name,
  size,
  link,
  xhtml_link,
  target = '_blank',
  showName = true,

}, currpage, title) => {
  const intl = useIntl();

  return (
    <div className="document_view_btns">
      {(name || size) && (
        <div className="details">
          <p>{showName && name}</p>
          <span>{size}</span>
        </div>
      )}
      <div className="icons">
        <a
          href={`${process.env.GATSBY_METADATA_SITE_URL}${intl.locale}/pdf/?file=https:${link}&title=${name}`}
          target={target}
          aria-label={
            intl.formatMessage({ id: 'common.open' }) +
            ' PDF, ' +
            name +
            ', ' +
            size +
            ', ' +
            intl.formatMessage({ id: 'ariaLabel.nouvel_onglet' })
          }
          onClick={() => trackEvent(name, currpage, title, 'download_eye')}
        >
          <IconPreview />
        </a>
        {/* <a
          href={link}
          target={target}
          download={name ? name : 'document'}
          aria-label={
            intl.formatMessage({ id: 'ariaLabel.download' }) +
            ' PDF, ' +
            name +
            ', ' +
            size +
            ', ' +
            intl.formatMessage({ id: 'ariaLabel.nouvel_onglet' })
            
          }
          onClick={()=>trackEvent(name, currpage, title,'download_chevron')}
        >
          <IconDownload />
        </a> */}
        {xhtml_link ?
          <a
            href={xhtml_link}
            download={name ? name : 'document'}
            aria-label={intl.formatMessage({ id: 'ariaLabel.download' }) + ' XHTML ZIP '}
            onClick={() => trackEvent(name, currpage, title)}
          >
            <IconDownloadZip />
          </a>
          : null
        }
      </div>
    </div>
  );
};

const DocumentView = ({ data, currpage, title }) => {
  return (
    <div className="documents_container">
      {data.map((document, index) => (
        <div className="document_view_line" key={generateKey(index)}>
          {getSingleDocument(document, currpage, title)}
        </div>
      ))}
    </div>
  );
};

export default DocumentView;
