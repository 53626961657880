import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DocumentSection from '../../../components/ContentType/Section/DocumentSection/DocumentSection';
import { getBreadCrumb, removeHtmlTag } from '../../../Utils/Utils';
import Layout from '../../../Layout';
import {
  IconLectureTime,
  VerticalYellowLineShape,
} from '../../../Atoms/Icons/Icons';
import Loader from '../../../Molecules/Loader/Loader';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import ScrollIcon from '../../../Molecules/ScrollIcon/ScrollIcon';
import Seo from '../../../components/Seo/Seo';
import SocialShare from '../../../Molecules/SocialShare/SocialShare';
import TextBlockComponent from '../../../components/ContentType/TextBlockComponent/TextBlockComponent';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VisuelComponent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import useMedias from '../../../hooks/useMedias';

import './article.scss';

const classNames = require('classnames');

const MediaPage = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();
  const [pageLoaded, setPageLoaded] = useState(false);

  const article = data.article;
  const imageArray = data.allImages.edges;
  const documentsArray = data.allDocuments.edges;
  const { locale } = pageContext;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(
    allMenu,
    article.path?.alias !== null && article.path?.alias !== undefined
      ? article.path?.alias
      : ''
  );
  const inlineImagesArray = data.inlineImages?.edges;
  const [allText, setAllText] = useState('');

  let tempDocArr = [];
  let allDocArr = [];
  let globalText = '';
  const metaTags =  article.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });


  useEffect(() => {


    setAllText(
      removeHtmlTag(
        `${article.title != undefined && article.title != null
          ? article.title
          : ''
        }.${article.field_sous_titre != null &&
          article.field_sous_titre != undefined
          ? article.field_sous_titre
          : ''
        }.${article.body?.processed != undefined &&
          article.body?.processed != null
          ? article.body?.processed
          : ''
        }${globalText}`
      )
    );

    document
      .getElementsByClassName('article_details')[0]
      ?.querySelectorAll('img')
      .forEach((el) => {
        inlineImagesArray
          .filter(
            (i) => i.node?.drupal_id === el.getAttribute('data-entity-uuid')
          )
          .forEach(({ node }) => (el.src = node.uri?.url));
      });


    if (article?.relationships?.field_category?.drupal_internal__tid === 8) {
      window.location.replace(documentsArray[0]?.node?.relationships?.field_media_document?.uri?.url)
    } else {
      setPageLoaded(true);
    }

  }, []);

  return (
    <Layout>
      <Seo
        title={article?.field_metatag?.title ? article?.field_metatag?.title : metaTitle}
        description={article?.field_metatag?.description ? article?.field_metatag?.description : metaDesc}
        shareImage={processUrl(getImage(imageArray, article.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_medium)}
      />
      {

        pageLoaded ? (
          <div className={classNames('page_template', 'page_article')}>
            <div className="banner_fullwidth_visuel">
              <div className="visuel">
                <img
                  src={processUrl(
                    getImage(
                      imageArray,
                      article.relationships?.field_image?.drupal_internal__mid
                    )?.image_style_uri?.gatsby_wide
                  )}
                  alt={article.title}
                />
              </div>
              <div className="visuel_overlay">
                <div className="wrapper_page">
                  <Breadcrumb
                    page_banner={true}
                    data={{
                      // grandParentPage: { title: 'Acceuile', url: '/' },
                      gparent: {
                        title: breadCrumb?.grandParent?.name,
                        url: breadCrumb?.grandParent?.link,
                      },
                      parentPage: {
                        title: breadCrumb?.parent?.name,
                        url: breadCrumb?.parent?.link,
                      },
                      currentPage: {
                        title: breadCrumb?.current?.name,
                        url: breadCrumb?.current?.link,
                      },
                      locale: article.langcode,
                    }}
                  />
                  <div className="title_audio">
                    {allText && article.field_version_audio && allText.length > 30 && (
                      <AudioComponent
                        text={intl.formatMessage({
                          id: 'detailsActivities.button.listen.label',
                        })}
                        textToRead={allText}
                        color="color_white"
                      />
                    )}
                  </div>
                </div>
                <div className="scroll_icon_container">
                  <ScrollIcon />
                </div>
              </div>
            </div>
            <div className={classNames('article_details', 'page_content')}>
              <div className="wrapper_page padding_wrapper">
                <div className="share_date">
                  <div className="publication_date">
                    <p className="date">
                      <span className="day">{article.day}</span>
                      <span className="month">{article.month.toUpperCase()}</span>
                      <span className="year">{article.year}</span>
                    </p>
                    <VerticalYellowLineShape className="bottom_line" />
                    <p className="lecture_time">
                      <IconLectureTime
                        className="time_icon"
                        aria-label={article.fields.readingTime.format}
                      />
                      <span>{article.fields.readingTime.format}</span>
                    </p>
                  </div>
                  <SocialShare
                    title={article.title}
                    linkTo={'/' + article.langcode + article.path.alias}
                    vertical={true}
                    hidemobile={true}
                  />
                </div>
                <div className="wrapper_page_xs">
                  <TitleSection
                    title={
                      article.field_title ? article.field_title : article.title
                    }
                    forceTagH1={true}
                  />
                  <div
                    className={classNames('highlight', 'wysiwyg_content')}
                    dangerouslySetInnerHTML={{ __html: article.field_sous_titre }}
                  />
                  <div
                    className="wysiwyg_content body_text"
                    dangerouslySetInnerHTML={{ __html: article.body?.processed }}
                  />
                </div>
              </div>
              {article?.relationships?.field_blocs?.map((block, i) => {
                switch (block.__typename) {
                  case 'block_content__block_image':
                    return (
                      <div className="wrapper_page_xs" key={i}>
                        <VisuelComponent
                          extremeLeft={
                            block?.field_alignment === 'left' ? true : false
                          }
                          extremeRight={
                            block?.field_alignment === 'right' ? true : false
                          }
                          visuel={getImage(
                            imageArray,
                            block.relationships?.field_image?.drupal_internal__mid
                          )}
                          size="two_third"
                        />
                      </div>
                    );

                  case 'block_content__block_texte_simple':
                    globalText += `${block.field_title?.processed != undefined &&
                      block.field_title?.processed != null
                      ? block.field_title?.processed
                      : ''
                      }.${block.field_subtitle?.processed !== undefined &&
                        block.field_subtitle?.processed != null
                        ? block.field_subtitle?.processed
                        : ''
                      }.${block.body?.processed != undefined &&
                        block.body?.processed != null
                        ? block.body?.processed
                        : ''
                      }`;
                    return (
                      <div className="wrapper_page_xs" key={i}>
                        <TextBlockComponent
                          title={{
                            type: block.field_title?.processed
                              ? 'section_title'
                              : undefined,
                            text:
                              block.field_title?.processed ||
                              block.field_subtitle?.processed,
                            subtitle: block.field_subtitle?.processed,
                          }}
                          description={block.body?.processed}
                        />
                      </div>
                    );

                  case 'block_content__block_document':
                    block?.relationships?.field_file?.forEach((file) => {
                      tempDocArr.push(
                        getDocument(documentsArray, file?.drupal_internal__mid)
                      );
                    });
                    tempDocArr[0] ? Object.assign(tempDocArr[0], { xhtmlLink: getDocument(documentsArray, block?.relationships?.field_media_xhtml?.drupal_internal__mid)?.uri?.url }) : null;

                    allDocArr.push({
                      title: removeHtmlTag(block?.field_title?.processed),
                      content: [
                        {
                          type: 'document',
                          files: tempDocArr,
                        },
                      ],
                    });

                    return (
                      <DocumentSection
                        documentDetails={allDocArr[0]}
                        titleSection={
                          block?.field_title?.processed != undefined &&
                            block?.field_title?.processed != null
                            ? true
                            : false
                        }
                        hasBackground={block?.field_background}
                        key={i}
                      ></DocumentSection>
                    );

                  case 'block_content__block_citation':
                    globalText += block.field_citation;
                    return (
                      <div className="wrapper_page_xs" key={i}>
                        <div className="quotemark_container">
                          <QuoteMark
                            size="m"
                            textWeight="m"
                            text={block.field_citation}
                            lang={locale}
                          />
                        </div>
                      </div>
                    );

                  case 'block_content__block_video':
                    const videoContent = {
                      content: [
                        {
                          type: 'video',
                          link: block.field_link?.uri,
                          title: removeHtmlTag(block.field_title?.processed),
                          image: getImage(
                            imageArray,
                            block.relationships?.field_image?.drupal_internal__mid
                          ),
                        },
                      ],
                    };
                    return <VideoSection imageArray={imageArray} videoDetails={videoContent}></VideoSection>;

                  default:
                    break;
                }
              })}
            </div>
          </div>
        ) : (
          <Loader customClass="largeMargin" />
        )
      }

    </Layout>
  );
};

MediaPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query ArticleTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
    $imagesUUID: [String!]
  ) {
    article: nodeArticle(
      path: { alias: { eq: $slug }, langcode: { eq: $locale } }
    ) {
      langcode
      month: created(locale: $locale, formatString: "MMM")
      day: created(locale: $locale, formatString: "DD")
      year: created(locale: $locale, formatString: "YYYY")
      path {
        alias
      }
      fields {
        readingTime {
          format
        }
      }
      field_metatag {
        description
        title
      }
      metatag {
        attributes {
          content
          name
        }
      }
      title
      field_title
      field_version_audio
      field_sous_titre
      body {
        processed
      }
      relationships {
        field_category {
          drupal_internal__tid
        }
        field_tags {
          name
          drupal_internal__tid
        }
        field_image {
          name
          drupal_internal__mid
        }
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__block_texte_simple {
              field_subtitle {
                processed
              }
              field_title {
                processed
              }
              body {
                processed
                summary
              }
            }
            ... on block_content__block_image {
              field_alignment
              relationships {
                field_image {
                  name
                  drupal_internal__mid
                }
              }
            }
            ... on block_content__block_citation {
              field_citation
            }
            ... on block_content__block_document {
              field_title {
                processed
              }
              field_background
              langcode
              relationships {
                field_file {
                  drupal_internal__mid
                }
                field_media_xhtml {
                  drupal_internal__mid
                }
              }
            }
            ... on block_content__block_video {
              id
              field_title {
                processed
              }
              field_link {
                uri
              }
              relationships {
                field_image {
                  drupal_internal__mid
                }
              }
            }
          }
        }
      }
    }
    inlineImages: allFileFile(
      filter: { langcode: { eq: "fr" }, drupal_id: { in: $imagesUUID } }
    ) {
      edges {
        node {
          drupal_id
          uri {
            url
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
          langcode
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default MediaPage;
