import * as React from 'react';

import PropTypes from 'prop-types';

import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import './TextBlockComponent.scss';

let classNames = require('classnames');

const TextBlockComponent = ({ title, description }) => {
  const { type, text, subtitle } = title || {};

  return (
    <div className={classNames('text_block_component', { 'section_marge': type === 'section_title' })}>
      {text && type === 'section_title' ? (
        <TitleSection
          title={text}
          description={subtitle}
        />
      ) : type === 'arrow' ? (
        <TitleBlock
          title={text}
        />
      ) : text ? (
        <h3 dangerouslySetInnerHTML={{ __html: text }} />
      ) : (<></>)
      }
      {description && <div dangerouslySetInnerHTML={{ __html: description }} ></div>}

    </div>
  );
};

TextBlockComponent.propTypes = {
  title: PropTypes.object,
  description: PropTypes.string
};

export default TextBlockComponent;
