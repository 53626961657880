import * as React from 'react';

import PropTypes from 'prop-types';

import { RoundedArrow } from '../../Atoms/Icons/Icons';

import './TitleBlock.scss';

let classNames = require('classnames');

function TitleBlock({ title, color, headingTag, titleBackground }) {
  return (
    <div className={classNames('title_block', { 'title_bg': titleBackground })}>
      {
        !titleBackground && (
          <RoundedArrow color={color} />
        )
      }
      {headingTag === 'p' ? (
        <p className={`${color}`}>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </p>
      ) : (
        <h3 className={color || ''}>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </h3>
      )}
    </div>
  );
}

TitleBlock.defaultProps = {
  title: 'Title',
  headingTag: 'h3',
  color: 'color_dark_bleu',
  titleBackground: false
};

TitleBlock.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  headingTag: PropTypes.string,
  titleBackground: PropTypes.bool
};

export default TitleBlock;
