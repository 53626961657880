import * as React from "react";
export const QuoteLeftFR = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      data-name="Group 1811"
      xmlns="http://www.w3.org/2000/svg"
      width="44.242"
      height="59.154"
      viewBox="0 0 44.242 59.154"
      aria-hidden="true"
    >
      <path
        data-name="Path 1914"
        d="M625.585,289.349v-.007a2.45,2.45,0,0,1,0-2.954v-.007s9.155-12.987,9.672-13.75c2.983-4.392,1.773-10.692-1.9-14.708l-21.308,28.709v0a2.565,2.565,0,0,0,0,2.46v0l14.739,18.333a11.08,11.08,0,0,0,1.074-13.979C627.345,292.69,625.585,289.349,625.585,289.349Z"
        transform="translate(-592.695 -257.923)"
        fill={newfill}
      />
      <path
        data-name="Path 1915"
        d="M611.709,289.151v-.007a2.45,2.45,0,0,1,0-2.954v-.007s9.155-12.987,9.672-13.75a11.078,11.078,0,0,0-1.074-13.978l-22.135,27.979v0a2.565,2.565,0,0,0,0,2.46v0l22.135,27.979a11.078,11.078,0,0,0,1.074-13.978C620.864,302.138,611.709,289.151,611.709,289.151Z"
        transform="translate(-597.858 -257.725)"
        fill={newfill}
      />
    </svg>
  );
};

export const QuoteRightFR = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      data-name="Group 1812"
      xmlns="http://www.w3.org/2000/svg"
      width="44.242"
      height="59.154"
      viewBox="0 0 44.242 59.154"
      aria-hidden="true"
    >
      <path
        data-name="Path 1916"
        d="M651.388,283.035v.007a2.45,2.45,0,0,1,0,2.954V286s-9.154,12.987-9.672,13.75c-2.983,4.392-1.771,10.692,1.9,14.708l21.308-28.709v0a2.565,2.565,0,0,0,0-2.46v0l-14.739-18.333a11.08,11.08,0,0,0-1.074,13.979C649.63,279.694,651.388,283.035,651.388,283.035Z"
        transform="translate(-640.036 -255.307)"
        fill={newfill}
      />
      <path
        data-name="Path 1917"
        d="M665.347,285.651v.007a2.45,2.45,0,0,1,0,2.954v.007s-9.154,12.987-9.672,13.75a11.076,11.076,0,0,0,1.076,13.978l22.134-27.979v0a2.565,2.565,0,0,0,0-2.46v0l-22.134-27.979a11.076,11.076,0,0,0-1.076,13.978C656.194,272.664,665.347,285.651,665.347,285.651Z"
        transform="translate(-634.957 -257.923)"
        fill={newfill}
      />
    </svg>
  );
};

export const QuoteLeftEn = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 68"
      width={28.344}
      aria-hidden="true"
    >
      <g>
        <path fill={newfill} d="M33.8,63.3V39c0-2.6-2.1-4.8-4.8-4.8l-12.2,0c-3.2,0-5.5-3.1-4.6-6.1c2.1-7.5,7.9-13.4,15.3-15.7
          c4-1.2,6.7-4.8,6.7-9l0-3.4c-2.5,0-4.8,0.3-7.1,0.8C11.6,4,0,17.8,0,34.2v29C0,65.9,2.1,68,4.8,68H29C31.6,68,33.8,65.9,33.8,63.3z
          "/>
        <path fill={newfill} d="M79.5,63.3V39c0-2.6-2.1-4.8-4.8-4.8l-12.2,0c-3.2,0-5.5-3.1-4.6-6.1c2.1-7.5,7.9-13.4,15.3-15.7
          c4-1.2,6.7-4.8,6.7-9L80,0c-2.5,0-4.8,0.3-7.1,0.8C57.4,4,45.8,17.8,45.8,34.2v29c0,2.6,2.1,4.8,4.8,4.8h24.2
          C77.4,68,79.5,65.9,79.5,63.3z"/>
      </g>
    </svg>
  );
};

export const QuoteRightEn = ({ fill }) => {
  const newfill = fill === "" ? "#FFED00" : fill;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 68"
      width="28.344"
      aria-hidden="true"
    >
      <g>
        <path fill={newfill} d="M46.2,4.8V29c0,2.6,2.1,4.8,4.8,4.8l12.2,0c3.2,0,5.5,3.1,4.6,6.1c-2.1,7.5-7.9,13.4-15.3,15.7
          c-4,1.2-6.7,4.8-6.7,9l0,3.4c2.5,0,4.8-0.3,7.1-0.8C68.4,64,80,50.2,80,33.8v-29C80,2.1,77.9,0,75.2,0H51C48.4,0,46.2,2.1,46.2,4.8
          z"/>
        <path fill={newfill} d="M0.5,4.8V29c0,2.6,2.1,4.8,4.8,4.8l12.2,0c3.2,0,5.5,3.1,4.6,6.1c-2.1,7.5-7.9,13.4-15.3,15.7
          c-4,1.2-6.7,4.8-6.7,9L0,68c2.5,0,4.8-0.3,7.1-0.8C22.6,64,34.2,50.2,34.2,33.8v-29c0-2.6-2.1-4.8-4.8-4.8H5.2
          C2.6,0,0.5,2.1,0.5,4.8z"/>
      </g>
    </svg>
  );
};
