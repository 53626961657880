import React, { useState } from 'react';

import './Loader.scss';

const classNames = require('classnames');

const Loader = ({ customClass }) => {

  return (
    <div className={classNames('loader', customClass)}>
      <svg id="svg-spinner" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" aria-hidden="true">
        <circle cx="24" cy="4" r="4" fill="#fff" />
        <circle cx="12.19" cy="7.86" r="3.7" fill="#000000" />
        <circle cx="5.02" cy="17.68" r="3.4" fill="#111111" />
        <circle cx="5.02" cy="30.32" r="3.1" fill="#222222" />
        <circle cx="12.19" cy="40.14" r="2.8" fill="#333333" />
        <circle cx="24" cy="44" r="2.5" fill="#444444" />
        <circle cx="35.81" cy="40.14" r="2.2" fill="#555555" />
        <circle cx="42.98" cy="30.32" r="1.9" fill="#666666" />
        <circle cx="42.98" cy="17.68" r="1.6" fill="#777777" />
        <circle cx="35.81" cy="7.86" r="1.3" fill="#888888" />
      </svg>
    </div>
  );
};


export default Loader;
